.multiSearchWrapper {
  position: absolute;
  bottom: 0;
  z-index: 9;
  display: flex;
  width: calc(var(--contentMaxWidthPages) - (32px * 2));
  max-width: 100%;
  margin: 0 auto;
  transform: translateY(50%);
  gap: 4px;
  align-items: center;
  background-color: var(--marketplaceColor);
  padding: 0.5rem;
  border-radius: 1.5rem;
  & .actionButton {
    background-color: var(--marketplaceColor);
    border-radius: 1rem;
    color: var(--colorWhite);
    height: 4rem;
    min-height: 4rem;
    padding: 0 16px;
    line-height: 1;
    width: auto;
    flex: 0;
    font-size: 1.5rem;
  
    &:hover {
      box-shadow: none;
      background-color: var(--marketplaceColorDark);
    }
  }
}

.multiSearchWrapper > * {
  flex-grow: 1;
  flex-basis: 0;
  font-family: var(--fontFamilyQuicksand);
  font-weight: 600 !important;
}
.multiSearchWrapper > div > div > button * {
  font-family: var(--fontFamilyQuicksand);
  font-weight: 600 !important;
}

.multiSearchWrapper > *:nth-child(-n+2) {
  flex-grow: 2;
}

.multiSearchWrapper button,
.filterButton button {
  border: none;
  white-space: nowrap;
  height: 4rem;
  font-size: 1rem;
  border-radius: 1rem;
  padding: 0 1.5rem;
}

.label {
  font-weight: 400;
  white-space: nowrap;
  color: white;
  margin: 0 24px;
}



.multiSearchWrapper button,
.buttonDefaultLabel {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & strong {
    font-weight: 500;
  }

  & span {
    font-size: 12px;
  }
}

.categoriesSelectorWrapper {
  position: relative;
}

.categoriesSelectorWrapper strong {
  font-weight: 500;
}

.categoriesSelector {
  position: absolute;
  z-index: 9;
  top: calc(100% + 8px);
  left: 0;
  padding: 24px;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.category {
  display: flex;
  gap: 4px;
  align-items: center;
}

.category label {
  white-space: nowrap;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0;
}

.input {
  border: 1px solid transparent;
  white-space: nowrap;
  height: 4rem;
  min-height: 4rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 1rem;
  padding: 0 1.5rem;
  text-align: center;
}

.input:hover {
  border-color: transparent;
}

.input:focus {
  outline: none;
  border-color: transparent;
}

input::placeholder {
  color: var(--colorGrey400)
}

.locationIcon {
  display: none;
}

/* Mobile view */
@media (max-width: 968px) {
  .multiSearchWrapper {
    box-sizing: border-box;
    position: relative;
    transform: none;
    flex-direction: column;
    bottom: 2rem;
    width: calc(100% - 2rem);
    padding: 1rem;
    margin: 1rem 0 0 0;
    align-items: stretch;
    justify-content: space-between;
  }

  .label {
    display: block;
    text-align: center;
    width: 100%;
    font-size: 18px;
    margin: 0;
    padding-bottom: 16px;
  }

  /* Floating elements */
  .filterButton button + * {
    width: 100%;
  }

  /* Floeting elements children */
  .filterButton button + * > form > div {
    padding: 0;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
  .filterButton button + form > div {
    padding: 0;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  /* Clear / Cancel / Apply buttons */
  .filterButton button + * > form > div > button {
    margin: 0;
    padding: 0;
  }
  .filterButton button + form > div > button {
    margin: 0;
    padding: 0;
  }

  .multiSearchWrapper button,
  .filterButton button {
    width: 100%;
  }

  .filterButton {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .actionButton {
    width: 100%;
    margin-top: 16px;
  }

  .categoriesSelector {
    width: 100%;
  }

  .category {
    gap: 12px;
  }

  .category label {
    margin-top: 4px;
  }
}
