@import '../../styles/customMediaQueries.css';
.root {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -5rem);
  width: 64rem;
  z-index: -1;
  overflow: visible;
  aria-hidden: true;

  @media (--viewportLarge) {
    transform: translate(-67rem, -5rem);
  }
}
.backgroundImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
