.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;
  font-family: var(--fontFamilyQuicksand);

  &:first-child {
    margin-top: 0;
  }

  & p:first-child {
    font-size: 2rem;
    font-family: var(--fontFamilyQuicksand);
    font-weight: 700;
    text-transform: uppercase;
  }

  & p + h1, p + h2, p + h3 {
    margin-top: 0;
  }
}
