.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;

  & p {
    color: var(--colorWhite);
    max-width: 75%;
  }
  & header {
    display: block;
    width: 100%;
    margin: 0 auto;
    & * {
      text-align: left;
    }
    & h1 {
      font-family: var(--fontFamilyQuicksand);
      font-weight: 600;
      max-width: 75%;
    }
    & h2 {
      font-family: var(--fontFamilyQuicksand);
      font-weight: 600;
    }
  }

  & .sectionContent {
    background-color: #ff0 !important;
  }

}
